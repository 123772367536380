import { Space, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
const mapDispatchToProps = (dispatch) => {
  return {};
};
class ConnectedUsersToMachines extends Component {
  render() {
    const columns = [
      {
        title: "Machine",
        key: "machine",
        render: (text, record) => {
          return <Space size="middle">{record.machine.name}</Space>;
        },
      },
      {
        title: "User",
        key: "user",
        render: (text, record) => {
          return <Space size="middle">{record.user.name}</Space>;
        },
      },
    ];
    return (
      <div>
        <h1>Machines</h1>
        <Table
          columns={columns}
          dataSource={this.props.usersToMachines}
          rowKey="id"
          pagination={{
            position: ["bottomCenter"],
            pageSize: 10,
            showSizeChanger: false,
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});
const UsersToMachines = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedUsersToMachines);
export default UsersToMachines;
