import { Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
const mapDispatchToProps = (dispatch) => {
  return {};
};
class ConnectedVideos extends Component {
  render() {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.name.localeCompare(b.played),
      },
      {
        title: "Times played",
        dataIndex: "played",
        key: "played",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.played - b.played,
      },
    ];
    return (
      <div>
        <Table
          columns={columns}
          dataSource={this.props.videos}
          rowKey="id"
          pagination={{
            position: ["bottomCenter"],
            pageSize: 10,
            showSizeChanger: false,
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});
const Videos = connect(mapStateToProps, mapDispatchToProps)(ConnectedVideos);
export default Videos;
