import React, { Component } from "react";
import { connect } from "react-redux";
/* import Answers from "./answers"; */
const mapDispatchToProps = (dispatch) => {
  return {};
};
class ConnectedGeneral extends Component {
  state = {
    topMachine: [],
    topUsers: [],
    topProducts: [],
  };

  componentDidMount() {
    const topMachines = this.props.topMachine.sort(
      (a, b) => b.popularity - a.popularity
    );
    const topUsers = this.props.topUsers
      .sort((a, b) => b.count - a.count)
      .slice(0, 3);
    const topProducts = this.props.topProducts
      .sort((a, b) => b.popularity - a.popularity)
      .slice(0, 3);
    this.setState({
      topMachine: topMachines,
      topProducts: topProducts,
      topUsers: topUsers,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.topMachine !== this.props.topMachine) {
      const topMachines = this.props.topMachine.sort(
        (a, b) => b.popularity - a.popularity
      );
      this.setState({ topMachine: topMachines });
    }
    if (prevProps.topUsers !== this.props.topUsers) {
      const topUsers = this.props.topUsers
        .sort((a, b) => b.count - a.count)
        .slice(0, 3);
      this.setState({ topUsers: topUsers });
    }
    if (prevProps.topProducts !== this.props.topProducts) {
      const topProducts = this.props.topProducts
        .sort((a, b) => b.popularity - a.popularity)
        .slice(0, 3);
      this.setState({ topProducts: topProducts });
    }
  }

  render() {
    return (
      <div>
        <h1>General statistics</h1>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <div>
              <h4>Total usage:</h4>
              <div>{this.props.totalUsage?.totalUsage}</div>
            </div>
            <div>
              <h4>Usage with client:</h4>
              <div>{this.props.clientUsage?.clientUsage}</div>
            </div>
          </div>
          <div style={{ marginLeft: "1em" }}>
            <div>
              <h4>Top machine:</h4>
              <div>{this.state.topMachine[0]?.name}</div>
            </div>
            <div>
              <h4>Top users:</h4>
              {this.state.topUsers.map((user) => {
                return <div key={user.id}>{user.name}</div>;
              })}
            </div>
          </div>
          <div style={{ marginLeft: "1em" }}>
            <h4>Most popular products</h4>
            {this.state.topProducts.map((product) => {
              return <div key={product.id}>{product.name}</div>;
            })}
          </div>
          <div style={{ marginLeft: "1em" }}>
            <h4>All time spent in the application:</h4>
            <div>
              {Math.floor(this.props.general.allTimeInApp / 60)} minutes
            </div>
          </div>
        </div>
        {/* <Answers answers={this.props.answers} /> */}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});
const General = connect(mapStateToProps, mapDispatchToProps)(ConnectedGeneral);
export default General;
