import { Button, Form, Input, message, Radio } from "antd";
import Axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import UnityContainer from "../Unity/unityContainer";
import "./login.scss";
const mapDispatchToProps = (dispatch) => {
  return {};
};

const formItemLayout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

class ConnectedAfterLogin extends Component {
  formRef = React.createRef();

  state = {
    accessType: null,
    attendees: 0,
    companies: null,
    answersSent: false,
  };

  handleChangeForm = ({ accessType }) => {
    this.setState({
      accessType: accessType,
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleSubmit = async () => {
    await Axios.post(
      "/1.0.0/answers",
      {
        accessType: this.state.accessType,
        attendees: this.state.attendees,
        companies: this.state.companies,
        userId: this.props.user.id,
      },
      { headers: { Authorization: this.props.token } }
    )
      .then(() => {
        this.setState({ answersSent: true });
      })
      .catch((err) => {
        console.log(err);
        message.error("Failed to save answers!", 4);
        this.setState({ answersSent: true });
      });
    /* if (this.props.user.groupId === 1) {
      this.props.history.push("/ea/unity");
    } else {
      this.props.history.push("/unity");
    } */
  };

  handleChangeRadio = (event) => {
    this.setState({ accessType: event.target.value });
  };

  render() {
    return (
      <div>
        {!this.state.answersSent && (
          <div style={{ paddingTop: "100px" }}>
            <div style={{ margin: "0 auto", width: "40%" }}>
              <Form
                {...formItemLayout}
                layout={"vertical"}
                ref={this.formRef}
                //onValuesChange={this.handleChangeForm}
                onFinish={this.handleSubmit}
              >
                <Form.Item
                  className="question-label"
                  label="1. Why are you accessing the experience today…"
                  style={{
                    backgroundColor: "#c41016",
                    color: "#ffffff",
                    padding: "15px",
                    fontSize: "1.2rem",
                  }}
                  name="accessType"
                  rules={[
                    {
                      required: "true",
                      message:
                        "Please select the reason you are accessing the experience!",
                    },
                  ]}
                >
                  <Radio.Group
                    value={this.state.accessType}
                    onChange={this.handleChangeRadio}
                  >
                    <Radio
                      value="1"
                      style={{ width: "100%", color: "#ffffff" }}
                    >
                      Prepare for an upcoming customer meeting
                    </Radio>
                    <Radio
                      value="2"
                      style={{ width: "100%", color: "#ffffff" }}
                    >
                      Meeting with customer
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label="2. What company(ies) are you walking through the mobile event experience?"
                  name="companies"
                  className="question-label"
                  style={{ color: "#ffffff" }}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the company names!",
                    },
                  ]}
                >
                  <Input
                    name="companies"
                    value={this.state.companies}
                    onChange={this.handleChange}
                    placeholder="Please enter your answer"
                  />
                </Form.Item>
                <Form.Item
                  label="3. Number of customer attendees"
                  className="question-label"
                  name="attendees"
                  style={{ color: "#ffffff" }}
                  initialValue={this.state.attendees}
                  rules={[
                    {
                      required: true,
                      message: "Please enter the number of attendees!",
                    },
                    {
                      min: 0,
                      message: "Participants can be 0 or higher!",
                    },
                  ]}
                >
                  <Input
                    name="attendees"
                    value={this.state.attendees}
                    onChange={this.handleChange}
                    placeholder="Please enter your answer"
                    type="number"
                    min={0}
                  />
                </Form.Item>
                <Form.Item style={{ textAlign: "center" }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                      fontSize: "1.2rem",
                      height: "45px",
                      width: "150px",
                      backgroundColor: "#c41016",
                      borderColor: "#c41016",
                      borderRadius: "0",
                    }}
                    size="large"
                  >
                    Next
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        )}
        <div style={{ display: this.state.answersSent ? "inline" : "none" }}>
          <UnityContainer />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  token: state.loginReducer.token,
});
const AfterLogin = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedAfterLogin);
export default AfterLogin;
