import { Button, Progress, Row } from "antd";
import Axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import Unity, { UnityContext } from "react-unity-webgl";
import { logout } from "../../actions/login";
import { ReactComponent as Logout } from "../../static/logout.svg";
import "./unity.scss";

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};
class ConnectedUnity extends Component {
  unityRef = React.createRef();
  state = {
    progression: 0,
    webGlLoaded: false,
    loadDate: new Date(),
  };

  componentDidMount() {
    window.addEventListener("beforeunload", this.onCloseTab());
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onCloseTab());
  }

  onCloseTab = async () => {
    const end = new Date();
    const spentTime = Math.floor(
      (end.getTime() - this.state.loadDate.getTime()) / 1000
    );
    await Axios.post("/1.0.0/statistics/add-viewtime-user", {
      time: spentTime,
    });
  };

  render() {
    const unityContext = new UnityContext({
      loaderUrl: "/webgl/Build/Eaton_hydraulics.loader.js",
      dataUrl: "/webgl/Build/Eaton_hydraulics.data",
      frameworkUrl: "/webgl/Build/Eaton_hydraulics.framework.js",
      codeUrl: "/webgl/Build/Eaton_hydraulics.wasm",
      streamingAssetsUrl: "/webgl/Build/StreamingAssets",
      modules: {
        PDFJS: "/webgl/pdf.js",
      },
    });

    unityContext.on("progress", (progression) => {
      const progression_ = progression * 100;
      if (progression === 1) {
        this.setState({
          webGlLoaded: true,
          progression: parseInt(progression_),
        });
      } else {
        this.setState({
          webGlLoaded: false,
          progression: parseInt(progression_),
        });
      }
    });

    const handleClickFullscreen = () => {
      unityContext.setUnityInstance(
        this.unityRef.current.unityContext.unityInstance
      );
      unityContext.setFullscreen(true);
    };

    const handleLogout = () => {
      unityContext.setUnityInstance(
        this.unityRef.current.unityContext.unityInstance
      );
      unityContext.send("ServerController", "OnLogoutFromBrowser");
      this.props.logout();
    };

    return (
      <div>
        <div
          style={{
            textAlign: "center",
            margin: "0 auto",
            width: "1280px",
            height: "720px",
            padding: "2em",
          }}
        >
          {!this.state.webGlLoaded && (
            <Row
              style={{
                textAlign: "center",
                width: "50%",
                margin: "0 auto",
                color: !this.props.cms ? "#ffffff" : "#000000",
              }}
            >
              <Progress
                percent={this.state.progression}
                status="active"
                {...(!this.props.cms && {
                  strokeColor: "#ffffff",
                  trailColor: "#f0a8aa",
                })}
                /* trailColor={this.props.cms && "#71A9CA"} */
              />
              <div style={{ textAlign: "center", width: "100%" }}>
                Loading the experience...
              </div>
            </Row>
          )}
          <div style={{ textAlign: "center" }}>
            <Unity
              unityContext={unityContext}
              width={1280}
              height={720}
              ref={this.unityRef}
            />
            {this.state.webGlLoaded && (
              <div id="unity-footer" onClick={handleClickFullscreen}>
                <div id="unity-fullscreen-button"></div>
                <div id="unity-build-title">Full Size</div>
              </div>
            )}
          </div>
        </div>
        <div style={{ float: "right", padding: "1.5em", marginTop: "5.5em" }}>
          <Button
            id="logout-button"
            type="primary"
            icon={<Logout />}
            onClick={handleLogout}
            style={{
              backgroundColor: "#c41016",
              borderColor: "#c41016",
              borderRadius: "0",
              color: "#ffffff",
              fontSize: "1.2rem",
              height: "45px",
            }}
          >
            &nbsp;Logout
          </Button>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});
const UnityBuild = connect(mapStateToProps, mapDispatchToProps)(ConnectedUnity);
export default UnityBuild;
