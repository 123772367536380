import axios from "axios";
import { LOGIN_ACTIONS } from "../constants/action-types";
import HttpService from "../services/http";
import Cookies from "universal-cookie";

export const login = (email, password) => {
  return async (dispatch) => {
    dispatch({ type: LOGIN_ACTIONS.TOKEN_LOADING });
    await axios
      .post("/1.0.0/users/login", {
        email,
        password,
      })
      .then((response) => {
        const tokenToSave = "Bearer " + response.data.accessToken;
        HttpService.setAxiosDefaults(tokenToSave);
        dispatch({
          type: LOGIN_ACTIONS.TOKEN_LOADED,
          payload: { token: tokenToSave, user: response.data.user },
        });
      })
      .catch((err) => {
        dispatch({
          type: LOGIN_ACTIONS.TOKEN_ERROR,
          payload: err.response ? err.response.data : err.message,
        });
        console.log(err.message);
      });
  };
};

export const refreshUser = (userId) => {
  return async (dispatch) => {
    await axios
      .get("/1.0.0/users/refresh-user", {
        params: { userId: userId },
      })
      .then((response) => {
        const tokenToSave = "Bearer " + response.data.accessToken;
        HttpService.setAxiosDefaults(tokenToSave);
        dispatch({
          type: LOGIN_ACTIONS.TOKEN_LOADED,
          payload: { token: tokenToSave, user: response.data.user },
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch({ type: LOGIN_ACTIONS.LOGOUT });
    const cookies = new Cookies();
    cookies.remove("token");
    cookies.remove("email");
  };
};
