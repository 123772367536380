import { Space, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
const mapDispatchToProps = (dispatch) => {
  return {};
};
class ConnectedProducts extends Component {
  render() {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: "Views",
        dataIndex: "views",
        key: "views",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.views - b.views,
      },
      {
        title: "Reading time (min)",
        dataIndex: "infoReadingTime",
        key: "infoReadingTime",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) =>
          Math.floor(a.infoReadingTime / 60) -
          Math.floor(b.infoReadingTime / 60),
        render: (string, record) => {
          return (
            <Space size="middle">
              {Math.floor(record.infoReadingTime / 60)}
            </Space>
          );
        },
      },
      {
        title: "Views of contents",
        dataIndex: "viewOfContent",
        key: "viewOfContent",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.viewOfContent - b.viewOfContent,
      },
    ];
    return (
      <div>
        <Table
          columns={columns}
          dataSource={this.props.products}
          rowKey="id"
          pagination={{
            position: ["bottomCenter"],
            pageSize: 10,
            showSizeChanger: false,
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});
const Products = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedProducts);
export default Products;
