export const LOGIN_ACTIONS = {
  TOKEN_LOADING: "TOKEN_LOADING",
  TOKEN_LOADED: "TOKEN_LOADED",
  TOKEN_ERROR: "TOKEN_ERROR",
  LOGOUT: "LOGOUT",
};

export const USERS_ACTIONS = {
  USERS_LOADING: "USERS_LOADING",
  USERS_LOADED: "USERS_LOADED",
  USERS_ERROR: "USERS_ERROR",
  USER_REGISTER_START: "USER_REGISTER_START",
  USER_REGISTER_SUCCESS: "USER_REGISTER_SUCCESS",
  USER_REGISTER_ERROR: "USER_REGISTER_ERROR",
  GROUPS_LOADING: "GROUPS_LOADING",
  GROUPS_LOADED: "GROUPS_LOADED",
  GROUPS_ERROR: "GROUPS_ERROR",
};
