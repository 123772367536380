/* eslint-disable no-useless-escape */
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input, message } from "antd";
import Axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import logo from "../../static/logo.png";
const mapDispatchToProps = (dispatch) => {
  return {};
};
class ConnectedRegister extends Component {
  formRef = React.createRef();

  state = {
    email: "",
    name: "",
    password: "",
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleFormSubmit = async () => {
    await Axios.post("/1.0.0/users/registration", {
      email: this.state.email,
      name: this.state.name,
      password: this.state.password,
    })
      .then((response) => {
        message.success("Successful registration, you can log in now!", 5);
        this.props.history.push("/login");
      })
      .catch((err) => {
        message.error("Error during registration!", 5);
        this.formRef.current.resetFields();
        this.setState({ email: "", name: "" });
      });
  };

  render() {
    return (
      <div>
        <div
          style={{
            backgroundColor: "#ffffff",
            textAlign: "center",
            padding: "1em",
            position: "absolute",
            width: "100%",
            top: "8%",
          }}
        >
          <img
            src={logo}
            alt="logo"
            className="login-page-icon"
            width={"15%"}
          />
        </div>
        <div className="login-page-container" style={{ display: "inline" }}>
          <div>
            <h1 style={{ color: "#ffffff" }}>Registration</h1>
          </div>
          <Form
            name="normal_login"
            className="login-form"
            onFinish={this.handleFormSubmit}
            ref={this.formRef}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter your e-mail address",
                },
                {
                  type: "email",
                  message: "Invalid e-mail address!",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="E-mail"
                name="email"
                autoComplete="off"
                value={this.state.email || ""}
                onChange={this.handleChange}
                style={{ color: "#1472A8" }}
              />
            </Form.Item>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please enter your name",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Name"
                name="name"
                autoComplete="off"
                value={this.state.name || ""}
                onChange={this.handleChange}
                style={{ color: "#1472A8" }}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter your password",
                },
                {
                  pattern:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/g,
                  message:
                    "Password must be at least 8 characters long (ideally, even 10 characters) and should consist of a seemingly random collection of uppercase and lowercase letters, numbers and special characters. With that in mind, here are some good examples of strong passwords: X5j13$#eCM1cG@Kdc.",
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                placeholder="Password"
                name="password"
                autoComplete="off"
                type="password"
                value={this.state.password || ""}
                onChange={this.handleChange}
                style={{ color: "#1472A8" }}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Register
              </Button>
              <Button
                type="default"
                className="login-form-button"
                style={{ marginLeft: "15px" }}
              >
                <NavLink to="/login">Back to login</NavLink>
              </Button>
            </Form.Item>
          </Form>
          <div style={{ textAlign: "justify" }}>
            <h3 style={{ color: "#ffffff" }}>
              After registration, just log in with the email address and
              password combination!
            </h3>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});
const Register = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedRegister);
export default Register;
