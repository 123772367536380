import React, { Component } from "react";
import Body from "./body";
import { Layout, Menu } from "antd";
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  LogoutOutlined,
  UserOutlined,
  PieChartOutlined,
  ProfileOutlined,
} from "@ant-design/icons";
import "./main.scss";
import logo from "../../static/logo.png";
import { connect } from "react-redux";
import { logout } from "../../actions/login";
import { NavLink } from "react-router-dom";
import HttpService from "../../services/http";

const { Header, Sider, Content } = Layout;

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};

class ConnectedMain extends Component {
  state = {
    collapsed: false,
    selectedKey: "1",
    width: 0,
  };

  constructor(props) {
    super(props);
    HttpService.setAxiosDefaults(props.token);
  }

  setWidth = () => {
    this.setState({ width: window.innerWidth });
  };

  componentDidMount = async () => {
    this.setDefaultSelectedKeyForMenu();
    this.setWidth();
    window.addEventListener("resize", this.setWidth);
  };

  componentWillUnmount = () => {
    HttpService.clearToken();
    window.removeEventListener("resize", this.setWidth);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setDefaultSelectedKeyForMenu();
    }
  }

  setDefaultSelectedKeyForMenu = () => {
    switch (this.props.location.pathname) {
      case "/ea/users":
        this.setState({ selectedKey: "2" });
        break;
      case "/ea/statistics":
        this.setState({ selectedKey: "3" });
        break;
      case "/ea/profile":
        this.setState({ selectedKey: "4" });
        break;
      default:
        this.setState({ selectedKey: null });
        break;
    }
  };

  handleSelect = (event) => {
    this.setState({ selectedKey: event.key });
  };

  logout = async () => {
    await this.props.logout();
    this.props.history.push("/login");
  };

  handleMenuClick = async (event) => {
    switch (event.key) {
      case "logout":
        await this.logout();
        break;

      default:
        break;
    }
  };

  renderMenu = () => {
    return (
      <Menu onClick={this.handleMenuClick}>
        {/* <Menu.Item key="profile" icon={<ProfileOutlined />}>
          <NavLink to="/fs/profile">Profile</NavLink>
        </Menu.Item> */}
        <Menu.Item key="logout" icon={<LogoutOutlined />}>
          Logout
        </Menu.Item>
      </Menu>
    );
  };

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };
  render() {
    return (
      <div style={{ height: "100%" }}>
        <Layout style={{ height: "100vh" }}>
          <Sider
            trigger={null}
            collapsible
            collapsed={this.state.collapsed}
            className={!this.state.collapsed && "fireshield-sider"}
          >
            <div
              className={
                this.state.collapsed
                  ? "logo-container logo-container-collapsed"
                  : "logo-container"
              }
            >
              <img src={logo} alt="Fireshield logo" className="logo-icon" />
            </div>
            <Menu
              theme="dark"
              mode="inline"
              selectedKeys={[this.state.selectedKey]}
              onSelect={this.handleSelect}
            >
              {/* <Menu.Item key="1" icon={<ProjectOutlined />}>
                <NavLink to="/fs/projekte" className="link-color">
                  Projekte
                </NavLink>
              </Menu.Item>
              {this.props.user.groupId === 1 && (
                <Menu.Item key="5" icon={<BarsOutlined />}>
                  <NavLink to="/fs/gesendeterapporte" className="link-color">
                    Gesendete Rapporte
                  </NavLink>
                </Menu.Item>
              )}
              {this.props.user.groupId === 1 && (
                <Menu.Item key="6" icon={<ToolOutlined />}>
                  <NavLink to="/fs/hersteller" className="link-color">
                    Hersteller
                  </NavLink>
                </Menu.Item>
              )}
              {this.props.user.groupId === 1 && (
                <Menu.Item key="7" icon={<ReconciliationOutlined />}>
                  <NavLink to="/fs/montageanleitungen" className="link-color">
                    Montageanleitungen
                  </NavLink>
                </Menu.Item>
              )}
              {this.props.user.groupId === 1 && (
                <Menu.Item key="8" icon={<ProfileOutlined />}>
                  <NavLink to="/fs/zertifikate" className="link-color">
                    Zertifikate
                  </NavLink>
                </Menu.Item>
              )}
              {this.props.user.groupId === 1 && (
                <Menu.Item key="9" icon={<UserOutlined />}>
                  <NavLink to="/fs/benutzer" className="link-color">
                    Benutzer
                  </NavLink>
                </Menu.Item>
              )} */}
              <Menu.Item key="2" icon={<UserOutlined />} color="#ed1c24">
                <NavLink to="/ea/users" className="link-color">
                  Registered
                </NavLink>
              </Menu.Item>
              <Menu.Item key="3" icon={<PieChartOutlined />} color="#ed1c24">
                <NavLink to="/ea/statistics" className="link-color">
                  Statistics
                </NavLink>
              </Menu.Item>
              <Menu.Item key="4" icon={<ProfileOutlined />} color="#ed1c24">
                <NavLink to="/ea/profile" className="link-color">
                  Profile
                </NavLink>
              </Menu.Item>
              <Menu.Item key="5" icon={<LogoutOutlined />}>
                <NavLink to="*" className="link-color" onClick={this.logout}>
                  Logout
                </NavLink>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout className="site-layout">
            <Header
              className="site-layout-background"
              style={{ padding: "0 0 0 26px" }}
            >
              {React.createElement(
                this.state.collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: this.toggle,
                }
              )}
              {/* <Dropdown.Button
                size="large"
                overlay={this.renderMenu}
                icon={
                  this.props.user.filename !== null ? (
                    <img
                      src={
                        process.env.REACT_APP_BASE_URL +
                        "/1.0.0/users/profile/" +
                        this.props.user.filename +
                        "?token=" +
                        this.props.token.split(" ")[1]
                      }
                      alt="logo"
                      style={{
                        borderRadius: "35px",
                        objectFit: "cover",
                        objectPosition: "50% 0",
                        width: "30px",
                        height: "30px",
                        margin: "0 0 16px 0",
                      }}
                    />
                  ) : (
                    <UserOutlined />
                  )
                }
                style={{
                  float: "right",
                  margin: "12px 26px 0 0",
                  /* width: "60px !important",
                }}
              /> */}
            </Header>
            <Content
              className="site-layout-background"
              style={{
                margin: "24px 16px",
                padding: 24,
                //height: "100%",
                minHeight: "unset" /* this.state.width < 1450 ? "120%" : "0" */,
              }}
            >
              <Body />
            </Content>
          </Layout>
        </Layout>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.loginReducer.token,
  user: state.loginReducer.user,
});

const Main = connect(mapStateToProps, mapDispatchToProps)(ConnectedMain);
export default Main;
