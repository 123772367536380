import React, { Component } from "react";
import { connect } from "react-redux";
import { logout } from "../../actions/login";
import UnityBuild from "./unity";
/* import { BackwardOutlined } from "@ant-design/icons"; */
const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(logout()),
  };
};
class ConnectedUnityContainer extends Component {
  handleLogout = () => {
    this.props.logout();
  };

  handleBackToLogin = () => {
    this.props.history.push("/login");
  };

  render() {
    return (
      <div>
        <div style={{ padding: "2em" }}>
          <UnityBuild />
        </div>       
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});
const UnityContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedUnityContainer);
export default UnityContainer;
