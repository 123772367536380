import { Space, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
const mapDispatchToProps = (dispatch) => {
  return {};
};
class ConnectedAnswers extends Component {
  render() {
    const columns = [
      {
        title: "Access time",
        dataIndex: "time",
        key: "time",
        render: (text, record) => {
          return (
            <Space size="middle">
              {new Date(record.created).toLocaleString()}
            </Space>
          );
        },
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) =>
          new Date(a.created).getTime() - new Date(b.created).getTime(),
      },
      {
        title: "Reason",
        dataIndex: "accessType",
        key: "accessType",
      },
      {
        title: "Companies",
        dataIndex: "companies",
        key: "companies",
      },
      {
        title: "Number of participants",
        dataIndex: "participants",
        key: "participants",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.participants - b.participants,
      },
      {
        title: "User",
        key: "user",
        render: (text, record) => {
          return (
            <Space size="middle">
              {record.user?.name}{" "}
              {record.user && <div>({record.user?.email})</div>}
            </Space>
          );
        },
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.user.email.localeCompare(b.user.email),
      },
    ];
    return (
      <div style={{ marginTop: "1em" }}>
        <h1>Answers</h1>
        <Table
          columns={columns}
          dataSource={this.props.answers}
          rowKey="id"
          pagination={{
            position: ["bottomCenter"],
            pageSize: 10,
            showSizeChanger: false,
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});
const Answers = connect(mapStateToProps, mapDispatchToProps)(ConnectedAnswers);
export default Answers;
