/* eslint-disable no-useless-escape */
import React, { Component } from "react";
import { Button, Input, message, Form } from "antd";
import { UserOutlined } from "@ant-design/icons";
import logo from "../../static/logo.png";
import "./login.scss";
import axios from "axios";
import { NavLink } from "react-router-dom";

export default class ForgotPassword extends Component {
  formRef = React.createRef();

  state = {
    email: "",
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleFormSubmit = async () => {
    await axios
      .post("/1.0.0/users/forgot-password", {
        email: this.state.email,
      })
      .then((response) => {
        message.success("Password has been reset, check your inbox!", 2);
        this.props.history.push("/login");
      })
      .catch((err) => {
        message.error("Error during password reset!", 2);
        this.formRef.current.resetFields();
        this.setState({ email: "" });
      });
  };
  render() {
    return (
      <div>
        <div
          style={{
            backgroundColor: "#ffffff",
            textAlign: "center",
            padding: "1em",
            position: "absolute",
            width: "100%",
            top: "8%",
          }}
        >
          <img
            src={logo}
            alt="logo"
            className="login-page-icon"
            width={"15%"}
          />
        </div>
        <div className="login-page-container">
          <Form
            name="normal_login"
            className="login-form"
            onFinish={this.handleFormSubmit}
            ref={this.formRef}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter your e-mail address",
                },
                {
                  type: "email",
                  message: "Invalid e-mail address!",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="E-mail"
                name="email"
                autoComplete="off"
                value={this.state.email || ""}
                onChange={this.handleChange}
                style={{ color: "#1472A8" }}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Reset password
              </Button>
              <Button
                type="default"
                className="login-form-button"
                style={{ marginLeft: "15px" }}
              >
                <NavLink to="/login">Back to login</NavLink>
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}
