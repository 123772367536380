import axios from "axios";

export default class HttpService {
  static setAxiosDefaults(token) {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
    axios.defaults.headers.common["Authorization"] = token;
  }

  static clearToken() {
    delete axios.defaults.headers.common["Authorization"];
  }
}
