import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

class ConnectedProtectedRoute extends Component {
  isAuth = () => {
    if (this.props.token !== null) {
      return true;
    } else {
      return false;
    }
  };

  hasRight = () => {
    if (this.props.rights.includes(this.props.user.groupId)) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.isAuth() ? (
          this.hasRight() ? (
            <Route
              path={this.props.path}
              //component={this.props.component}
              render={(props) =>
                React.createElement(this.props.component, {
                  ...props,
                  cms: this.props.cms,
                })
              }
            />
          ) : (
            <Redirect from="**" to="/ea/missing-right" />
          )
        ) : (
          <Redirect from="**" to="/login" />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.loginReducer.token,
  user: state.loginReducer.user,
});

const ProtectedRoute = connect(mapStateToProps, null)(ConnectedProtectedRoute);
export default ProtectedRoute;
