import Statistics from "../components/Statistocs/statistics";
import Profile from "../components/Users/profile";
import Users from "../components/Users/users";

export const routes = [
  {
    path: "/ea/users",
    component: Users,
    rights: [1],
    cms: true,
  },
  {
    path: "/ea/statistics",
    component: Statistics,
    rights: [1],
    cms: true,
  },
  {
    path: "/ea/profile",
    component: Profile,
    rights: [1],
    cms: true,
  },
];
