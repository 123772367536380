import { Space, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
const mapDispatchToProps = (dispatch) => {
  return {};
};
class ConnectedReviews extends Component {
  render() {
    const columns = [
      {
        title: "Product",
        key: "product",
        render: (text, record) => {
          return <Space size="middle">{record.product.name}</Space>;
        },
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.product.name.localeCompare(b.product.name),
      },
      {
        title: "User",
        key: "user",
        render: (text, record) => {
          return <Space size="middle">{record.user.email}</Space>;
        },
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.user.email.localeCompare(b.user.email),
      },
      {
        title: "Time (min)",
        key: "time",
        dataIndex: "time",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => Math.floor(a.time / 60) - Math.floor(b.time / 60),
        render: (string, record) => {
          return <Space size="middle">{Math.floor(record.time / 60)}</Space>;
        },
      },
    ];
    return (
      <div>
        <Table
          columns={columns}
          dataSource={this.props.reviews}
          rowKey="id"
          pagination={{
            position: ["bottomCenter"],
            pageSize: 10,
            showSizeChanger: false,
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});
const Reviews = connect(mapStateToProps, mapDispatchToProps)(ConnectedReviews);
export default Reviews;
