import React, { useEffect } from "react";
import {
  Redirect,
  BrowserRouter as Router,
  Route,
  Switch,
} from "react-router-dom";
import Login from "./components/Login/login";
import Main from "./components/skeleton/main";
import ProtectedRoute from "./components/ProtectedRoute/protected-route";
import { Helmet } from "react-helmet";
import axios from "axios";
import ForgotPassword from "./components/Login/forgotpassword";
import Register from "./components/Login/register";
import AfterLogin from "./components/Login/afterLoginForm";
/* import UnityContainer from "./components/Unity/unityContainer"; */
import "./App.css";
import Cookies from "universal-cookie";

const App = () => {
  useEffect(() => {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
    //REACT_APP_BASE_URL
    const cookies = new Cookies();
    cookies.set("baseURL", process.env.REACT_APP_BASE_URL);
  }, []);

  return (
    <div style={{ zIndex: "4" }}>
      <Helmet>
        <title>DANFOSS</title>
      </Helmet>
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/register" component={Register} />
          {/*  <Route exact path="/guest-unity" component={UnityContainer} /> */}
          <ProtectedRoute path="/ea" component={Main} rights={[1, 2]} />
          {/* <ProtectedRoute
            path="/unity"
            component={UnityContainer}
            rights={[1, 2]}
          /> */}
          <ProtectedRoute
            path="/afterlogin"
            component={AfterLogin}
            rights={[1, 2]}
          />
          <Redirect from="**" to="/login" />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
