import { Button, Tabs } from "antd";
import Axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import Answers from "./answers";
import Content from "./content";
import General from "./general";
import Products from "./products";
import Reviews from "./reviews";
import Session from "./session";
import Users from "./users";
import UsersToMachines from "./usersToMachines";
import Videos from "./videos";
import { DownloadOutlined } from "@ant-design/icons";
const mapDispatchToProps = (dispatch) => {
  return {};
};
class ConnectedStatistics extends Component {
  state = {
    reviews: [],
    videos: [],
    products: [],
    contents: [],
    usersToMachines: [],
    answers: [],
    general: {},
    totalUsage: null,
    clientUsage: null,
    topUsers: [],
    topProducts: [],
    topMachine: [],
    participants: [],
    time: [],
    session: [],
  };

  componentDidMount = async () => {
    await Promise.all([
      Axios.get("/1.0.0/statistics/products")
        .then((response) => {
          this.setState({ products: response.data.products });
        })
        .catch((err) => console.log(err.message)),
      Axios.get("/1.0.0/statistics/videos")
        .then((response) => {
          this.setState({ videos: response.data.videos });
        })
        .catch((err) => console.log(err.message)),
      Axios.get("/1.0.0/statistics/reviews")
        .then((response) => {
          this.setState({ reviews: response.data.reviews });
        })
        .catch((err) => console.log(err.message)),
      Axios.get("/1.0.0/statistics/content")
        .then((response) => {
          this.setState({ contents: response.data.content });
        })
        .catch((err) => console.log(err.message)),
      Axios.get("/1.0.0/statistics/general")
        .then((response) => {
          this.setState({ general: response.data.general });
        })
        .catch((err) => console.log(err.message)),
      Axios.get("/1.0.0/statistics/answers")
        .then((response) => {
          this.setState({ answers: response.data.answers });
        })
        .catch((err) => console.log(err.message)),
      Axios.get("/1.0.0/statistics/user-to-machines")
        .then((response) => {
          this.setState({ usersToMachines: response.data.usersToMachines });
        })
        .catch((err) => console.log(err.message)),
      Axios.get("/1.0.0/statistics/total-usage")
        .then((response) => {
          this.setState({ totalUsage: response.data.usage });
        })
        .catch((err) => console.log(err.message)),
      Axios.get("/1.0.0/statistics/total-usage-client")
        .then((response) => {
          this.setState({ clientUsage: response.data.usage });
        })
        .catch((err) => console.log(err.message)),
      Axios.get("/1.0.0/statistics/top-users")
        .then((response) => {
          this.setState({ topUsers: response.data.topUsers });
        })
        .catch((err) => console.log(err.message)),
      Axios.get("/1.0.0/statistics/top-products")
        .then((response) => {
          this.setState({ topProducts: response.data.topProducts });
        })
        .catch((err) => console.log(err.message)),
      Axios.get("/1.0.0/statistics/top-machine")
        .then((response) => {
          this.setState({ topMachine: response.data.topMachines });
        })
        .catch((err) => console.log(err.message)),
      Axios.get("/1.0.0/statistics/participants")
        .then((response) => {
          this.setState({ participants: response.data.participants });
        })
        .catch((err) => console.log(err.message)),
      Axios.get("/1.0.0/statistics/time")
        .then((response) => {
          this.setState({ time: response.data.timeStatistics });
        })
        .catch((err) => console.log(err.message)),
      Axios.get("/1.0.0/statistics/machine-session")
        .then((response) => {
          this.setState({ session: response.data.sessionStatistics });
        })
        .catch((err) => console.log(err.message)),
    ]);
  };

  handleDownloadExcelStatistics = async () => {
    await Axios.get("/1.0.0/statistics/export", {
      headers: {
        "Content-Disposition": "attachment;filename=statistics.xls",
        "Content-Type": "application/octet-stream",
      },
      responseType: "blob",
    }).then((response) => {
      console.log(response.data);
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "statistics.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  };

  render() {
    const { TabPane } = Tabs;
    return (
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <h1>Statistics</h1>
          <div>
            <Button type="primary" onClick={this.handleDownloadExcelStatistics}>
              <DownloadOutlined />
              Excel export
            </Button>
          </div>
        </div>
        <Tabs defaultActiveKey="1">
          <TabPane tab="General" key="1">
            <General
              general={this.state.general}
              answers={this.state.answers}
              totalUsage={this.state.totalUsage}
              clientUsage={this.state.clientUsage}
              topUsers={this.state.topUsers}
              topProducts={this.state.topProducts}
              topMachine={this.state.topMachine}
            />
          </TabPane>
          <TabPane tab="Participants" key="7">
            <Users
              participants={this.state.participants}
              time={this.state.time}
            />
          </TabPane>
          <TabPane tab="Session" key="8">
            <Session session={this.state.session} />
          </TabPane>
          <TabPane tab="Customer overview" key="9">
            <Answers answers={this.state.answers} />
          </TabPane>
          <TabPane tab="Products" key="2">
            <Products products={this.state.products} />
          </TabPane>
          <TabPane tab="Reviews" key="3">
            <Reviews reviews={this.state.reviews} />
          </TabPane>
          <TabPane tab="Content" key="4">
            <Content content={this.state.contents} />
          </TabPane>
          <TabPane tab="Videos" key="5">
            <Videos videos={this.state.videos} />
          </TabPane>
          <TabPane tab="Machines" key="6">
            <UsersToMachines usersToMachines={this.state.usersToMachines} />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});
const Statistics = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedStatistics);
export default Statistics;
