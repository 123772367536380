import { ResponsiveBar } from "@nivo/bar";
import React, { Component } from "react";
import { connect } from "react-redux";
const mapDispatchToProps = (dispatch) => {
  return {};
};
class ConnectedSession extends Component {
  state = {
    keys: [
      "Wheel Loader",
      "Excavator",
      "Drilling Machine",
      "Concrete Pump",
      "Telehandler",
    ],
  };

  render() {
    return (
      <div>
        <div
          style={{
            height: "500px",
            width: "100%",
            textAlign: "center",
            margin: "30px auto",
          }}
        >
          <ResponsiveBar
            data={this.props.session}
            indexBy="type"
            keys={this.state.keys}
            groupMode="grouped"
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            colors={{ scheme: "category10" }}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Machine/Session",
              legendPosition: "middle",
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Count",
              legendPosition: "middle",
              legendOffset: -40,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});
const Session = connect(mapStateToProps, mapDispatchToProps)(ConnectedSession);
export default Session;
