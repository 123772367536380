import React, { Component } from "react";
import { Table, Button, Modal, Form, Input, Select, Space } from "antd";
import { connect } from "react-redux";
import {
  getAllUsers,
  registerUser,
  modifyUser,
  deleteUser,
} from "../../actions/users";
import Highlighter from "react-highlight-words";
import {
  SearchOutlined,
  SaveFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import Axios from "axios";

const mapDispatchToProps = (dispatch) => {
  return {
    getAllUsers: (token) => dispatch(getAllUsers(token)),
    registerUser: (user, token) => dispatch(registerUser(user, token)),
    modifyUser: (user, token) => dispatch(modifyUser(user, token)),
    deleteUser: (userId) => dispatch(deleteUser(userId)),
  };
};

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

class ConnectedUsers extends Component {
  formRef = React.createRef();

  state = {
    users: [],
    loaded: false,
    visible: false,
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    group: 2,
    selectedUser: {},
    modify: false,
    selectedAccessLogs: [],
    accessLogsVisible: false,
  };

  componentDidMount = async () => {
    //await this.props.getAllGroups(this.props.token);
    await this.getUsers();
    this.setState({ loaded: true });
  };
  setDefaultValues = () => {
    this.setState({
      email: "",
      password: "",
      firstname: "",
      lastname: "",
      group: 2,
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangeGroup = (event) => {
    this.setState({ group: event });
  };

  getUsers = async () => {
    this.setState({ loaded: false });
    await this.props.getAllUsers(this.props.token);
    if (this.props.usersStatus) {
      const users = this.props.users.map((user) => {
        const date = new Date(user.created * 1000);
        return {
          key: user.id,
          id: user.id,
          email: user.email,
          timeSpentInApp: user.timeSpentInApp,
          created: date.toLocaleDateString(),
        };
      });
      this.setState({ users: users });
    }
    this.setState({ loaded: true });
  };

  handleClickNewUser = () => {
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false, modify: false });
    this.formRef.current.resetFields();
  };

  handleSave = async () => {
    await this.formRef.current.validateFields();
    const errors = this.formRef.current.getFieldsError();
    if (
      !errors.filter((error) => {
        return error.errors.length !== 0;
      })[0]
    ) {
      await this.submitForm();
    }
    await this.getUsers();
  };

  /* submitForm = async () => {
    if (this.state.modify) {
      const user = {
        id: this.state.selectedUser.id,
        email: this.state.email,
        password: this.state.password,
        firstname: this.state.firstname,
        lastname: this.state.lastname,        
      };
      await this.props.modifyUser(user, this.props.token);
    } else {
      const user = {
        email: this.state.email,
        password: this.state.password,
        firstname: this.state.firstname,
        lastname: this.state.lastname,        
      };
      await this.props.registerUser(user, this.props.token);
    }
    if (this.props.saveStatus) {
      this.setDefaultValues();
      this.formRef.current.resetFields();
      this.setState({ visible: false, modify: false });
    }
  };

  handleModifyUser = (user) => {
    this.setState(
      {
        selectedUser: user,
        modify: true,
        email: user.email,
        firstname: user.firstname,
        lastname: user.lastname,
        group: user.group === "Admin" ? 1 : 2,
      },
      async () => {
        await this.handleClickNewUser();
        this.formRef.current.setFieldsValue({
          email: user.email,
          firstname: user.firstname,
          lastname: user.lastname,
          group: user.group === "Admin" ? 1 : 2,
        });
      }
    );
  };

  handleDeleteUser = async (user) => {
    await this.props.deleteUser(user.id);
    await this.getUsers();
  }; */

  handleClickAccessLogs = async (record) => {
    await Axios.get("/1.0.0/answers", { params: { userId: record.id } })
      .then((response) => {
        this.setState({
          selectedAccessLogs: response.data.answers,
          accessLogsVisible: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  closeAccessLogs = () => {
    this.setState({
      selectedAccessLogs: [],
      accessLogsVisible: false,
    });
  };

  getColumnSearchProps = (dataIndex, searchLabel) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${searchLabel}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 100 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text, record) =>
      this.state.searchedColumn === dataIndex ? (
        <Space align="center">
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[this.state.searchText]}
            autoEscape
            textToHighlight={text.toString()}
          />
        </Space>
      ) : (
        <Space align="center">{text}</Space>
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    const columns = [
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
        ...this.getColumnSearchProps("email", "email"),
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.email.localeCompare(b.email),
      },
      {
        title: "Creation date",
        dataIndex: "created",
        key: "datum",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) =>
          new Date(a.created).getTime() - new Date(b.created).getTime(),
      },
      {
        title: "Time spent in app (min)",
        dataIndex: "timeSpentInApp",
        key: "timeSpentInApp",
        render: (string, record) => {
          return (
            <Space size="middle">
              {Math.floor(record.timeSpentInApp / 60)}
            </Space>
          );
        },
        sorter: (a, b) =>
          Math.floor(a.timeSpentInApp / 60) - Math.floor(b.timeSpentInApp / 60),
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Access log",
        key: "accessLog",
        render: (text, record) => (
          <Space size="middle">
            <Button onClick={() => this.handleClickAccessLogs(record)}>
              Access log
            </Button>
          </Space>
        ),
      },
    ];
    const accessLogColumns = [
      {
        title: "Access time",
        dataIndex: "time",
        key: "time",
        render: (text, record) => {
          return (
            <Space size="middle">
              {new Date(record.created).toLocaleString()}
            </Space>
          );
        },
        sorter: (a, b) =>
          new Date(a.time).getTime() - new Date(b.time).getTime(),
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Reason",
        dataIndex: "accessType",
        key: "accessType",
      },
      {
        title: "Companies",
        dataIndex: "companies",
        key: "companies",
      },
      {
        title: "Attendees",
        dataIndex: "participants",
        key: "attendees",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.participants - b.participants,
      },
    ];
    return (
      <div>
        <h1>Users</h1>
        {/* <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div style={{ float: "right" }}>
            <Button
              type="primary"
              onClick={this.handleClickNewUser}
              style={{ margin: "0.5em" }}
            >
              <PlusCircleFilled />
              Neu Benutzer
            </Button>
          </div>
        </div> */}
        <Table
          columns={columns}
          dataSource={this.state.users}
          //locale={{ emptyText: "Keine Daten" }}
          loading={!this.props.usersStatus && !this.state.loaded}
          pagination={{ position: ["bottomCenter"], pageSize: 10 }}
          rowKey="id"
        />

        <Modal
          maskClosable={false}
          visible={this.state.visible}
          title="Neue Benutzer"
          onOk={this.handleSave}
          onCancel={this.handleCancel}
          footer={[
            <Button key="back" onClick={this.handleCancel}>
              <CloseCircleFilled />
              Stornieren
            </Button>,
            <Button
              key="submit"
              type="primary"
              //loading={loading}
              onClick={this.handleSave}
            >
              <SaveFilled />
              {this.state.modify ? "Ändern" : "Registrieren"}
            </Button>,
          ]}
        >
          <Form
            ref={this.formRef}
            {...formItemLayout}
            name="register"
            onFinish={this.submitForm}
            scrollToFirstError
          >
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                {
                  type: "email",
                  message: "Die Eingabe ist ungültig Email!",
                },
                {
                  required: true,
                  message: "Bitte geben Sie Ihre E-Mail Adresse ein!",
                },
              ]}
            >
              <Input
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item
              name="password"
              label="Passwort"
              rules={[
                {
                  required: this.state.modify ? false : true,
                  message: "Bitte geben Sie Ihr Passwort ein!",
                },
              ]}
              hasFeedback
            >
              <Input.Password
                name="password"
                value={this.state.password}
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item
              name="firstname"
              label="Vorname"
              rules={[
                {
                  required: true,
                  message: "Bitte geben Sie Ihre Vorname ein!",
                  whitespace: true,
                },
              ]}
            >
              <Input
                name="firstname"
                value={this.state.firstname}
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item
              name="lastname"
              label="Nachname"
              rules={[
                {
                  required: true,
                  message: "Bitte geben Sie Ihre Nachname ein!",
                  whitespace: true,
                },
              ]}
            >
              <Input
                name="lastname"
                value={this.state.lastname}
                onChange={this.handleChange}
              />
            </Form.Item>
            <Form.Item name="group" label="Gruppe">
              <Select
                name="group"
                defaultValue={2}
                value={this.state.group}
                onChange={this.handleChangeGroup}
              >
                {this.props.groups.map((group) => {
                  return (
                    <Option value={group.id} key={group.id}>
                      {group.name === "admin" ? "Admin" : "Benutzer"}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button hidden htmlType="submit"></Button>
            </Form.Item>
          </Form>
        </Modal>
        <Modal
          visible={this.state.accessLogsVisible}
          title="Access log"
          centered
          maskClosable={false}
          destroyOnClose
          onCancel={this.closeAccessLogs}
          footer={null}
          width={850}
        >
          <Table
            dataSource={this.state.selectedAccessLogs}
            columns={accessLogColumns}
            rowKey="id"
            pagination={{ position: ["bottomCenter"], pageSize: 10 }}
          />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.loginReducer.user,
  token: state.loginReducer.token,
  users: state.usersReducer.users,
  groups: state.usersReducer.groups,
  usersStatus: state.usersReducer.status,
  saveStatus: state.usersReducer.saveStatus,
});

const Users = connect(mapStateToProps, mapDispatchToProps)(ConnectedUsers);
export default Users;
