import { USERS_ACTIONS } from "../constants/action-types";

const initialState = {
  users: [],
  status: false,
  message: "",
  groups: [],
  saveStatus: false,
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case USERS_ACTIONS.USERS_LOADING:
      return Object.assign({}, state, {
        users: [],
        status: false,
        message: "",
      });
    case USERS_ACTIONS.USERS_LOADED:
      return Object.assign({}, state, {
        users: action.payload.users,
        status: true,
        message: "",
      });
    case USERS_ACTIONS.USERS_ERROR:
      return Object.assign({}, state, {
        users: [],
        status: false,
        message: action.payload,
      });
    case USERS_ACTIONS.GROUPS_LOADING:
      return Object.assign({}, state, {
        groups: [],
        status: false,
        message: "",
      });
    case USERS_ACTIONS.GROUPS_LOADED:
      return Object.assign({}, state, {
        groups: action.payload.groups,
        status: true,
        message: "",
      });
    case USERS_ACTIONS.GROUPS_ERROR:
      return Object.assign({}, state, {
        groups: [],
        status: false,
        message: action.payload,
      });
    case USERS_ACTIONS.USER_REGISTER_START:
      return Object.assign({}, state, {
        saveStatus: false,
      });
    case USERS_ACTIONS.USER_REGISTER_SUCCESS:
      return Object.assign({}, state, {
        saveStatus: true,
      });
    case USERS_ACTIONS.USER_REGISTER_ERROR:
      return Object.assign({}, state, {
        saveStatus: false,
        message: action.payload,
      });

    default:
      return state;
  }
};

export default usersReducer;
