/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { Input, Button, Form, message, Row, Col } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import logo from "../../static/logo.png";
import "./login.scss";
import { connect } from "react-redux";
import { login } from "../../actions/login";
import Cookies from "universal-cookie";

const mapDispatchToProps = (dispatch) => {
  return {
    login: (email, password) => dispatch(login(email, password)),
  };
};

class ConnectedLogin extends Component {
  formRef = React.createRef();

  state = {
    email: "",
    password: "",
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleFormSubmit = async () => {
    await this.props.login(this.state.email, this.state.password);
    if (this.props.status) {
      message.success("Successful login", 2);
      const cookies = new Cookies();
      cookies.set("token", this.props.token);
      cookies.set("email", this.props.user.email);
      if (this.props.user.groupId === 1) {
        this.props.history.push("/ea/users");
      } else {
        this.props.history.push("/afterlogin");
      }
      /* } else {
        this.props.history.push("/unity");
      } */
    } else {
      message.error("Error during login process", 2);
      this.formRef.current.resetFields();
      this.setState({ email: "", password: "" });
    }
  };

  render() {
    return (
      <div>
        <div
          style={{
            backgroundColor: "#ffffff",
            textAlign: "center",
            padding: "1em",
            position: "absolute",
            width: "100%",
            top: "8%",
          }}
        >
          <img
            src={logo}
            alt="logo"
            className="login-page-icon"
            width={"15%"}
          />
        </div>
        <div className="login-page-container">
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={this.handleFormSubmit}
            ref={this.formRef}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Please enter your e-mail address",
                },
                /* process.env.REACT_APP_MODE === "development"
                  ? {
                      type: "email",
                      message: "Invalid e-mail address!",
                    }
                  : {
                      pattern: /^[\w-\.]+@eaton.com$/g,
                      message: "Invalid e-mail address!",
                    }, */
                {
                  type: "email",
                  message: "Invalid e-mail address!",
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="E-mail"
                name="email"
                autoComplete="off"
                value={this.state.email || ""}
                onChange={this.handleChange}
                style={{ color: "#ed1c24" }}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please enter your password!" },
              ]}
              style={{ marginBottom: "12px" }}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
                name="password"
                value={this.state.password || ""}
                onChange={this.handleChange}
                style={{ color: "#ed1c24" }}
              />
            </Form.Item>
            <Form.Item style={{ textAlign: "center", marginBottom: "12px" }}>
              <a
                className="login-form-forgot"
                //href="*"
                style={{ color: "#ffffff" }}
                onClick={() => {
                  this.props.history.push("/forgot-password");
                }}
              >
                Forgot password
              </a>
            </Form.Item>

            <Row>
              <Col span={12} style={{ textAlign: "center" }}>
                <Form.Item>
                  <a
                    className="login-form-forgot"
                    //href="*"
                    style={{ color: "#ffffff" }}
                    onClick={() => {
                      this.props.history.push("/register");
                    }}
                  >
                    Register
                  </a>
                </Form.Item>
              </Col>
              <Col span={12} style={{ textAlign: "center" }}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    style={{
                      width: "100%",
                      color: "#ffffff",
                      backgroundColor: "#c41016",
                      borderColor: "#c41016",
                      borderRadius: "0",
                    }}
                  >
                    Login
                  </Button>
                </Form.Item>
              </Col>
            </Row>
            {/*  <Row>
              <Col span={24} style={{ textAlign: "center" }}>
                <Form.Item>
                  <a
                    className="login-form-forgot"
                    //href="*"
                    style={{ color: "#ffffff" }}
                    onClick={() => {
                      this.props.history.push("/guest-unity");
                    }}
                  >
                    Guest entry
                  </a>
                </Form.Item>
              </Col>
            </Row> */}
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.loginReducer.token,
  user: state.loginReducer.user,
  status: state.loginReducer.status,
});

const Login = connect(mapStateToProps, mapDispatchToProps)(ConnectedLogin);
export default Login;
