import React, { Component } from "react";
import { connect } from "react-redux";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveRadar } from "@nivo/radar";
const mapDispatchToProps = (dispatch) => {
  return {};
};
class ConnectedUsers extends Component {
  state = {
    keys: ["Training", "Meeting", "Total"],
  };

  /* componentDidUpdate(prevProps, prevState) {
    if (prevProps.participants !== this.props.participants) {
      const keys = this.props.participants.map((part) => {
        return part.date;
      });
      this.setState({ keys: keys });
    }
  } */

  render() {
    return (
      <div>
        <div
          style={{
            height: "500px",
            width: "100%",
            textAlign: "center",
            margin: "30px auto",
          }}
        >
          <ResponsiveBar
            data={this.props.participants}
            indexBy="date"
            keys={this.state.keys}
            groupMode="grouped"
            margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
            colors={{ scheme: "category10" }}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Date",
              legendPosition: "middle",
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Participants",
              legendPosition: "middle",
              legendOffset: -40,
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemOpacity: 1,
                    },
                  },
                ],
              },
            ]}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
          />
        </div>
        <div
          style={{
            height: "500px",
            width: "100%",
            textAlign: "center",
            margin: "30px auto",
          }}
        >
          <ResponsiveRadar
            data={this.props.time}
            keys={["Training", "Meeting"]}
            indexBy="type"
            maxValue="auto"
            margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
            curve="linearClosed"
            borderWidth={2}
            borderColor={{ from: "color" }}
            gridLevels={5}
            gridShape="circular"
            gridLabelOffset={36}
            enableDots={true}
            dotSize={10}
            dotColor={{ theme: "background" }}
            dotBorderWidth={2}
            dotBorderColor={{ from: "color" }}
            enableDotLabel={true}
            dotLabel="value"
            dotLabelYOffset={-12}
            colors={{ scheme: "category10" }}
            fillOpacity={0.25}
            blendMode="multiply"
            animate={true}
            motionConfig="wobbly"
            isInteractive={true}
            legends={[
              {
                anchor: "top-left",
                direction: "column",
                translateX: -50,
                translateY: -40,
                itemWidth: 80,
                itemHeight: 20,
                itemTextColor: "#999",
                symbolSize: 12,
                symbolShape: "circle",
                effects: [
                  {
                    on: "hover",
                    style: {
                      itemTextColor: "#000",
                    },
                  },
                ],
              },
            ]}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});
const Users = connect(mapStateToProps, mapDispatchToProps)(ConnectedUsers);
export default Users;
