import { Space, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
const mapDispatchToProps = (dispatch) => {
  return {};
};
class ConnectedContent extends Component {
  render() {
    const columns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: "Views",
        dataIndex: "views",
        key: "views",
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.views - b.views,
      },
      {
        title: "Product",
        key: "product",
        render: (text, record) => {
          return <Space size="middle">{record.product.name}</Space>;
        },
        sortDirections: ["descend", "ascend"],
        sorter: (a, b) => a.product.name.localeCompare(b.product.name),
      },
    ];
    return (
      <div>
        <Table
          columns={columns}
          dataSource={this.props.content}
          rowKey="id"
          pagination={{
            position: ["bottomCenter"],
            pageSize: 10,
            showSizeChanger: false,
          }}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({});
const Content = connect(mapStateToProps, mapDispatchToProps)(ConnectedContent);
export default Content;
