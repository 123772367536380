/* eslint-disable no-useless-escape */
import React, { Component } from "react";
import { Form, Input, Button, Col, Row, message } from "antd";
import { connect } from "react-redux";
import { modifyProfileUser } from "../../actions/users";
import { refreshUser } from "../../actions/login";
import HttpService from "../../services/http";

const formItemLayout = {
  labelCol: {
    xs: {
      span: 4,
    },
    sm: {
      span: 4,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 12,
    },
  },
};

const mapDispatchToProps = (dispatch) => {
  return {
    modifyProfileUser: (formData) => dispatch(modifyProfileUser(formData)),
    refreshUser: (userId) => dispatch(refreshUser(userId)),
  };
};

class ConnectedProfile extends Component {
  formRef = React.createRef();

  state = {
    email: "",
    password: "",
    oldPassword: "",
    firstname: "",
    lastname: "",
    position: "",
    phoneNumber: "",
    file: [],
  };

  componentDidMount = () => {
    const { user } = this.props;
    this.setState({
      email: user.email,
    });

    this.formRef.current.setFieldsValue({
      email: user.email,
    });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  refreshFormAndUser = async () => {
    await this.props.refreshUser(this.props.user.id);
    const { user } = this.props;
    this.setState({
      email: user.email,
    });
    HttpService.setAxiosDefaults(this.props.token);
    this.formRef.current.resetFields();
    this.formRef.current.setFieldsValue({
      email: user.email,
    });
  };

  submitForm = async () => {
    await this.props.modifyProfileUser({
      id: this.props.user.id,
      email: this.state.email,
      password: this.state.password,
    });
    if (this.props.saveStatus) {
      message.success("Successful save", 5);
      await this.refreshFormAndUser();
    } else {
      message.error("Save error!", 5);
    }
  };

  handleClickDeletePicture = async () => {
    await this.props.deleteProfilePicture(this.props.user.id);
    await this.refreshFormAndUser();
  };

  render() {
    return (
      <div>
        <Row>
          <Col span={24} style={{ padding: "1em" }}>
            <h1>
              <strong>Profile</strong>
            </h1>
            <Form
              ref={this.formRef}
              {...formItemLayout}
              name="register"
              onFinish={this.submitForm}
              scrollToFirstError
            >
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your e-mail address",
                  },
                  {
                    type: "email",
                    message: "Invalid e-mail address!",
                  },
                ]}
              >
                <Input
                  name="email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please enter your password",
                  },
                  {
                    pattern:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d@$!%*?&]{8,}$/g,
                    message:
                      "Password must be at least 8 characters long (ideally, even 10 characters) and should consist of a seemingly random collection of uppercase and lowercase letters, numbers and special characters. With that in mind, here are some good examples of strong passwords: X5j13$#eCM1cG@Kdc.",
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  name="password"
                  value={this.state.password}
                  onChange={this.handleChange}
                />
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" type="primary">
                  Save
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  token: state.loginReducer.token,
  user: state.loginReducer.user,
  saveStatus: state.usersReducer.saveStatus,
});

const Profile = connect(mapStateToProps, mapDispatchToProps)(ConnectedProfile);
export default Profile;
